<template>
  <div class="tab-switcher">
    <div class="arrow-wrapper" @click="handleBackTab">
      <i class="icon">arrow_back</i>
    </div>
    <div class="dot-wrapper">
      <template v-for="tab in listTab" :key="tab">
        <div
          class="dot"
          :class="{ 'is-active': tab === current }"
          @click="$emit('change', tab)"
        />
      </template>
    </div>
    <div class="arrow-wrapper" @click="handleForwardTab">
      <i class="icon">arrow_forward</i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    current: String,
  },

  emits: ["change"],
  setup(props, context) {
    const listTab = ["stages", "types", "factors"];
    const handleBackTab = () => {
      const current = props.current;
      const indexOfCurrent = listTab.indexOf(current);
      const backTab = listTab[indexOfCurrent - 1];

      if (backTab) {
        context.emit("change", backTab);
      } else {
        context.emit("change", listTab[listTab.length - 1]);
      }
    };
    const handleForwardTab = () => {
      const current = props.current;
      const indexOfCurrent = listTab.indexOf(current);
      const forwardTab = listTab[indexOfCurrent + 1];
      if (forwardTab) {
        context.emit("change", forwardTab);
      } else {
        context.emit("change", listTab[0]);
      }
    };

    return {
      listTab,
      handleBackTab,
      handleForwardTab,
    };
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:map';
@use '@/styles/variables';

.tab-switcher {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.dot-wrapper {
  margin: 0 8px;
}

.arrow-wrapper {
  display: flex;
  background: #595959;
  height: 32px;
  align-items: center;
  width: 32px;
  justify-content: center;
  border-radius: 50px;
  cursor: pointer;

  .icon {
    cursor: pointer;
    color: map.get(variables.$colors, "white", "100");
  }
}

.dot {
  display: inline-block;
  border-radius: 24px;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background-color: map.get(variables.$colors, "gray", "300");

  &:not(.is-active):hover {
    background-color: map.get(variables.$colors, "gray", "500");
    transition: background-color 0.4s ease-in-out;
  }

  &.is-active {
    width: 16px;
    animation: expand ease-in-out 0.2s;
    background-color: map.get(variables.$colors, "gray", "500");
  }
}

@keyframes expand {
  0% {
    width: 8px;
  }
  100% {
    width: 16px;
  }
}

.xen + .xen {
  margin-left: 8px;
}
</style>
