<template>
  <template v-if="fetchingResult">
    <div class="lgz">
      <progress-element active />
    </div>
  </template>
  <template v-else>
    <template v-if="tab === 'stages'">
      <div class="fade-in">
        <div class="vff">5 Stages of Corporate Innovation Maturity</div>
        <div class="lhz">
          <MaturityScoreBrenchMark
            v-if="industryMaturityScore.length && !isAnonymous"
            :industryMaturityScore="industryMaturityScore"
            :maturityScore="maturityStages.score"
            :industryName="industryName"
          />
          <maturity-stages-component
            v-else
            :data="[maturityStages.score]"
            mode="chart"
          />
        </div>
      </div>
      <tab-switcher-component
        class="vbl"
        :current="tab"
        @change="handleTabChanged"
      />
      <div class="fade-in">
        <div class="pxc">
          Your company is a{{ maturityStages.level.level === 2 ? "n" : "" }}
          {{ maturityStages.level.title }}
        </div>
        <div class="gkw">
          <div>Your Score:</div>
          <badge-element class="mtc" :color="maturityStages.level.color">
            {{ maturityStages.score }}/90
          </badge-element>
        </div>
        <div class="lhz">
          <span class="bzg">Character</span>
          <template
            v-for="(description, index) in maturityStages?.descriptions?.en"
            :key="description"
          >
            <template v-if="index === 0">
              <span class="mtc" v-html="description"></span>
            </template>
            <template v-else>
              <div>
                <span v-html="description"></span>
              </div>
            </template>
          </template>
        </div>
      </div>
    </template>

    <template v-else-if="tab === 'types'">
      <div class="fade-in">
        <div class="vff">4 Types of Innovation Aptitude</div>
        <div class="lhz">
          <MaturityScatterApitude
            v-if="industryMaturityScore.length && !isAnonymous"
            :industryMaturityScore="industryMaturityScore"
            :peopleScore="aptitudeTypes.score[1]"
            :techScore="aptitudeTypes.score[0]"
            :industryName="industryName"
          />
          <aptitude-types-component
            v-else
            :data="aptitudeTypes?.score"
            mode="chart"
          />
        </div>
      </div>
      <tab-switcher-component
        class="vbl"
        :current="tab"
        @change="handleTabChanged"
      />
      <div class="fade-in">
        <div class="pxc">
          Your Innovation Aptitude is {{ aptitudeTypes.type }} Driven.
        </div>
        <div class="kcd">
          <div class="ybw">
            <span>People score:</span>
            <span class="bzg mtc">{{ aptitudeTypes.score[1] }}</span>
          </div>
          <div class="ybw">
            <span>Technology score:</span>
            <span class="bzg mtc">{{ aptitudeTypes.score[0] }}</span>
          </div>
        </div>
        <div class="lhz">
          <span class="bzg">Character</span>
          <template
            v-for="(description, index) in aptitudeTypes?.descriptions?.en"
            :key="description"
          >
            <template v-if="index === 0">
              <span class="mtc">{{ description }}</span>
            </template>
            <template v-else>
              <div>{{ description }}</div>
            </template>
          </template>
        </div>
      </div>
    </template>

    <template v-else-if="tab === 'factors'">
      <div class="fade-in">
        <div class="vff">6 Factors of Corporate Innovation</div>
        <div class="lhz">
          <MaturityFactorBrenchMark
            v-if="industryMaturityScore.length && !isAnonymous"
            :industryMaturityScore="industryMaturityScore"
            :factorsDetail="factorsDetail"
            :industryName="industryName"
          />
          <maturity-factors-component
            v-else
            :data="maturityFactors?.score"
            mode="chart"
          />
        </div>
      </div>
      <tab-switcher-component
        class="vbl"
        :current="tab"
        @change="handleTabChanged"
      />
      <div class="fade-in">
        <div class="vff">
          Your 6-Factor Scores Deep Dive
        </div>
        <div class="lhz">
          <template
            v-for="(factor, index) in factorsDetail"
            :key="`factor-${index}`"
          >
            <div class="fnb">
              <div class="hwt">
                <div class="fqm" :class="[`is-${factor.color}`]">
                  {{ factor.topic }}
                </div>
                <badge-element class="mtc" :color="factor.color">
                  {{ factor.totalScore }}/15
                </badge-element>
              </div>
              <template v-for="(score, i) in factor.score" :key="`score-${i}`">
                <linear-gauge-component
                  class="zzm"
                  :label="factor.descriptions?.en[i]"
                  :color="factor.color"
                  :value="score"
                  :max="5"
                />
              </template>
            </div>
          </template>
        </div>
      </div>
    </template>

    <div class="xev">
      <router-link :to="{ name: 'invitation' }">
        <button-element class="ekm" outlined>
          Invite Colleagues
        </button-element>
      </router-link>
      <button-element
        class="mnx"
        @click="downloadDialog.show()"
        :disabled="downloadingReport"
      >
        Download
        <progress-element class="mtc" v-show="downloadingReport" active />
      </button-element>
    </div>
    <router-link class="btz" :to="{ name: 'results' }">
      Back
    </router-link>
  </template>

  <dialog-element ref="downloadDialog">
    <div class="zfx">
      <p>
        Feel free to download your CIMM Report. Please contact us via email at
        <a
          href="mailto:cimm@riseaccel.com?subject=Ask for CIMM report consulting"
          >cimm@riseaccel.com</a
        >
        if you want our Innovation Consultant to walk you through the analysis
        of the report.
      </p>
      <div class="lwv" v-if="false">
        <mobile-input-component
          class="zzm"
          label="Perferred Contact Number"
          :disabled="downloadingReport"
          :error="mobileInputError"
          @keyup="validateMobileInput"
          v-model="contactInputs.mobileNumber"
        />
        <input-element
          label="Work Email Address"
          :disabled="downloadingReport"
          :error="emailInputError"
          v-model="contactInputs.email"
        />
      </div>
      <div class="nbc">
        <button-element
          class="nzu"
          :disabled="downloadingReport"
          @click="downloadReport(route.params.id)"
        >
          Download Result
          <progress-element class="mtc" v-show="downloadingReport" active />
        </button-element>
        <button-element
          v-if="false"
          class="nzu"
          :disabled="downloadingReport"
          @click="sendContact(route.params.id)"
        >
          Download and Contact Me
          <progress-element class="mtc" v-show="downloadingReport" active />
        </button-element>
      </div>
    </div>
  </dialog-element>

  <dialog-element ref="thankYouDialog">
    <div class="zfx">
      <div class="ape">
        Thank you for your interest.<br />
        Our CIMM expert will be contacting you soon!
      </div>
      <div class="pyp">
        <button-element @click="thankYouDialog.hide()">OK</button-element>
      </div>
    </div>
  </dialog-element>

  <dialog-element ref="errorDialog">
    <div class="zfx">
      <div class="asd">Error</div>
      <div class="lwv">
        <template v-if="errorCode === 'permission-denied'"
          >Couldn't find result with given result ID or result access
          denied</template
        >
        <template v-else
          >Oops! Something went wrong. Please try again later.</template
        >
      </div>
      <div class="ooh">
        <template v-if="errorCode === 'permission-denied'">
          <router-link :to="{ name: 'results' }">
            <button-element @click="errorDialog.hide()">Back</button-element>
          </router-link>
        </template>
        <template v-else>
          <button-element @click="errorDialog.hide()">Close</button-element>
        </template>
      </div>
    </div>
  </dialog-element>
</template>

<script>
import { computed, onMounted, ref, defineAsyncComponent } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import store from "@/store";
import { fetchMaturityIndustry } from "@/services/api/benchmark";
import {
  EXPORT_REPORT,
  GET_RESULT,
  GET_USER,
  SEND_CONTACT,
  FETCH_PROFILE,
} from "@/store/action-types";
import AptitudeTypes from "@/components/aptitude-types/aptitude-types.vue";
import MaturityFactorsComponent from "@/components/maturity-factors/maturity-factors.vue";
import MaturityStagesComponent from "@/components/maturity-stages/maturity-stages.vue";
import LinearGauge from "@/components/linear-gauge/linear-gauge.vue";
import MobileInput from "@/components/mobile-input/mobile-input.vue";
const MaturityScoreBrenchMark =  defineAsyncComponent(()=>import("@/components/MaturityScoreBrenchMark.vue"));
const MaturityFactorBrenchMark =  defineAsyncComponent(()=>import("@/components/MaturityFactorBrenchMark.vue"));
const MaturityScatterApitude =  defineAsyncComponent(()=>import("@/components/MaturityScatterApitude.vue"));
import TabSwitcher from "./tab-switcher.vue";
import factorsData from "./maturity-factors.json";

export default {
  components: {
    "aptitude-types-component": AptitudeTypes,
    "linear-gauge-component": LinearGauge,
    "maturity-factors-component": MaturityFactorsComponent,
    "maturity-stages-component": MaturityStagesComponent,
    "mobile-input-component": MobileInput,
    "tab-switcher-component": TabSwitcher,
    MaturityScoreBrenchMark,
    MaturityFactorBrenchMark,
    MaturityScatterApitude,
  },

  async beforeRouteEnter(to, from, next) {
    let user = await store.dispatch(GET_USER);
    if (!user) {
      next({ name: "login" });
      return;
    }
    next();
  },

  setup() {
    let route = useRoute();
    let store = useStore();
    let downloadDialog = ref(null);
    let errorCode = ref("");
    const industryMaturityScore = ref([]);
    let errorDialog = ref(null);
    let hasValidateOnce = ref(false);
    let contactInputs = ref({
      email: "",
      mobileNumber: "",
    });
    let tab = ref("stages");
    let downloadingReport = ref(false);
    let fetchingResult = ref(false);
    let thankYouDialog = ref(null);
    let industryId = computed(( )=> route.query.industryId);
    let industryName = computed(() => store.state.user.industry.name);
    let result = computed(() => store.state.test.result);
    let isAnonymous = computed(() => store.state.user.user.isAnonymous);
    let maturityStages = computed(() => {
      let maturityStages = result.value.results?.find(
        (val) => val.ruleName === "maturity-stages"
      );
      maturityStages = {
        ...maturityStages,
        level: mappedLevel(maturityStages?.score),
      };
      return maturityStages;
    });

    let recommendations = computed(() =>
      result.value.results?.find((val) => val.ruleName === "recommendations")
    );

    let aptitudeTypes = computed(() => {
      let aptitudeTypes = result.value.results?.find(
        (val) => val.ruleName === "aptitude-types"
      );
      aptitudeTypes = {
        ...aptitudeTypes,
        type: mappedType(aptitudeTypes?.score),
      };
      return aptitudeTypes;
    });

    let maturityFactors = computed(() =>
      result.value.results?.find((val) => val.ruleName === "maturity-factors")
    );

    let factorsDetail = computed(() => {
      //current user score factor 
      let resultFactorDetail = factorsData.map((factor) => {
        let maturityFactor = result.value.results?.find(
          (val) => val.ruleName === `maturity-factors/${factor.name}`
        );
        return {
          ...factor,
          ...maturityFactor,
          score: [...maturityFactor.score],
          totalScore: maturityFactor.score.reduce((sum, score) => sum + score),
        };
      });
      return resultFactorDetail;
    });

    let mappedLevel = (score) => {
      if (score <= 30) {
        return {
          level: 1,
          title: "Newcomer",
          color: "amber",
        };
      }
      if (score <= 45) {
        return {
          level: 2,
          title: "Explorer",
          color: "orange",
        };
      }
      if (score <= 60) {
        return {
          level: 3,
          title: "Challenger",
          color: "purple",
        };
      }
      if (score <= 75) {
        return {
          level: 4,
          title: "Practitioner",
          color: "blue",
        };
      }
      return {
        level: 5,
        title: "Champion",
        color: "green",
      };
    };

    let mappedType = (score) => {
      if (score[0] <= 22 && score[1] >= 23) return "People";
      if (score[0] >= 23 && score[1] >= 23) return "People and Technology";
      if (score[0] <= 22 && score[1] <= 22)
        return "Neither People Nor Technology";
      if (score[0] >= 23 && score[1] <= 22) return "Technology";
      return "";
    };

    let handleTabChanged = (newTab) => {
      tab.value = newTab;
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    let formatDate = (createdAt) => {
      let newDate = new Date(createdAt);
      let date = newDate.getDate();
      let month = months[newDate.getMonth()];
      let year = newDate.getFullYear();
      let hour = newDate.getHours();
      let minute = newDate.getMinutes();
      return `${date} ${month} ${year} ${hour}-${minute}`;
    };

    let downloadReport = async (resultId) => {
      try {
        downloadingReport.value = true;
        let response = await store.dispatch(EXPORT_REPORT, resultId);
        if (response.status === 200) {
          let downloadElement = document.createElement("a");

          let filename = `Result-${formatDate(result.value.createdAt)}`;
          downloadElement.setAttribute("download", filename);

          let downloadUrl = window.URL.createObjectURL(response.data);
          downloadElement.href = downloadUrl;

          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);

          setTimeout(() => {
            window.URL.revokeObjectURL(downloadUrl);
          }, 100);
        }
      } catch (error) {
        errorCode.value = error.code;
        errorDialog.value.show();
      } finally {
        downloadingReport.value = false;
        downloadDialog.value.hide();
      }
    };

    let emailInputError = computed(() => {
      if (!hasValidateOnce.value) return null;
      let emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
      let { email } = contactInputs.value;
      if (!email) return "Field is required";
      if (!emailPattern.test(email)) {
        return "Please enter a valid email";
      }
      return null;
    });

    let mobileInputError = computed(() => {
      if (!hasValidateOnce.value) return null;
      let split = contactInputs.value?.mobileNumber.split(" ") ?? [];
      return !contactInputs.value?.mobileNumber ||
        (split?.length === 2 && split[1] === "")
        ? "Field is required"
        : null;
    });

    let validateMobileInput = (event) => {
      let key = window.event ? event.keyCode : event.which;
      let { mobileNumber } = contactInputs.value;
      let split = contactInputs.value?.mobileNumber.split(" ") ?? [];
      let isNumber = /^\d+$/.test(split[1]) && split[1];
      if (event.keyCode === 8 || event.keyCode === 46) {
        return true;
      }
      if (!isNumber && (key < 48 || key > 57)) {
        contactInputs.value.mobileNumber = mobileNumber.slice(
          0,
          mobileNumber.length - 1
        );
        return false;
      }
      return true;
    };

    let validate = () => {
      hasValidateOnce.value = true;
      let { email, mobileNumber } = contactInputs.value;
      let validate = true;
      if (mobileInputError.value) validate = false;
      if (emailInputError.value) validate = false;
      if (!validate) return validate;

      return {
        email,
        mobileNumber,
      };
    };

    let sendContact = async (resultId) => {
      let data = validate();
      if (!data) return;
      await downloadReport(resultId);
      try {
        downloadingReport.value = true;
        await store.dispatch(SEND_CONTACT, { ...data });
      } catch (error) {
        errorCode.value = error.code;
        errorDialog.value.show();
      } finally {
        downloadingReport.value = false;
        downloadDialog.value.hide();
        thankYouDialog.value.show();
      }
    };

    let fetchUserProfile = async () => {
      await store.dispatch(FETCH_PROFILE);
    };

    let fetchResult = async (id) => {
      try {
        await store.dispatch(GET_RESULT, id);
      } catch (error) {
        errorCode.value = error.code;
        errorDialog.value.show();
      }
    };

    let fetchIndustryBrenchmarkData = async (industryId) => {
      const industrtMaturityScoreRes = await fetchMaturityIndustry(
        industryId
      );
      industryMaturityScore.value = industrtMaturityScoreRes.data;
    };

    onMounted(async () => {
      try {
        fetchingResult.value = true;
        //TODO: fetch parallel for reduce time render
        await Promise.all([
          fetchUserProfile(),
          fetchIndustryBrenchmarkData(industryId.value),
          fetchResult(route.params.id)
        ])
        // if (route.params?.id) await fetchResult(route.params.id);
        fetchingResult.value = false;
      } catch (e) {
        fetchingResult.value = false;
      }
    });

    return {
      aptitudeTypes,
      contactInputs,
      errorCode,
      errorDialog,
      downloadDialog,
      downloadReport,
      downloadingReport,
      emailInputError,
      mobileInputError,
      factorsDetail,
      handleTabChanged,
      fetchingResult,
      maturityFactors,
      maturityStages,
      recommendations,
      route,
      sendContact,
      tab,
      thankYouDialog,
      validateMobileInput,
      industryMaturityScore,
      isAnonymous,
      industryName,
    };
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:map';
@use '@/styles/variables';

.xev {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 24px;
}

.mnx {
  justify-content: center;
  margin-top: 16px;
}

.ekm {
  justify-content: center;
  width: 100%;
  color: map.get(variables.$colors, "gray", "500");
  background-color: map.get(variables.$colors, "gray", "300");
}

.zfx {
  padding: 24px;
}

.asd {
  font-weight: map.get(variables.$font-weights, "bold");
  font-size: map.get(variables.$font-sizes, "lg");
}

.lwv {
  margin: 16px 0 24px;
}

.ooh {
  text-align: right;
}

.pyp {
  text-align: center;
  margin-top: 24px;
}

.lgz {
  text-align: center;
}

.btz {
  color: map.get(variables.$colors, "gray", "500");
  text-decoration: underline;
}

.vff {
  font-weight: map.get(variables.$font-weights, "bold");
  font-size: map.get(variables.$font-sizes, "lg");
  margin-bottom: 24px;
  text-align: center;
}

.pxc {
  font-weight: map.get(variables.$font-weights, "bold");
  font-size: map.get(variables.$font-sizes, "lg");
  text-align: center;
}

.lhz {
  background-color: white;
  padding: 16px;
  margin-bottom: 24px;
  border-radius: 4px;
}

.vbl {
  margin: 24px 0;
}

.gkw {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 24px;
}

.kcd {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 24px;
}

.ybw {
  text-align: center;

  & + & {
    margin-left: 16px;
  }
}

.bzg {
  line-height: 120%;
  font-weight: map.get(variables.$font-weights, "bold");
}

.ape {
  font-weight: map.get(variables.$font-weights, "bold");
  text-align: center;
}

.mtc {
  margin-left: 8px;
}

.hwt {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.nbc {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
}

.nzu {
  justify-content: center;
  width: 100%;
  margin-top: 16px;
}

.fws {
  color: map.get(variables.$colors, "gray", "500");
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.fqm {
  font-weight: map.get(variables.$font-weights, "bold");

  @each $name in "orange", "blue", "green", "amber", "indigo", "purple" {
    &.is-#{$name} {
      color: map.get(variables.$colors, $name, "500");
    }
  }
}

.fnb + .fnb {
  margin-top: 24px;
}

.zzm {
  margin-bottom: 8px;
}

.fade-in {
  animation: fadeIn ease 0.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width: map.get(variables.$breakpoints, "sm")) {
  .xev {
    flex-direction: row;
    justify-content: space-between;
  }

  .mnx {
    margin-top: 0;
  }

  .nbc {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .nzu {
    margin-top: 0;
    width: initial;
  }
}

@media (min-width: map.get(variables.$breakpoints, "lg")) {
  .lhz {
    padding: 24px;
  }

  .vbl {
    margin: 32px 0;
  }

  .zfx {
    padding: 32px;
  }

  .lwv {
    margin: 24px 0 32px;
  }

  .ooh {
    text-align: center;
  }
}
</style>
