<template>
  <div class='linear-gauge'>
    <div class='lbl'>{{ label }}</div>
    <div class='con'>
      <div class='lin'
        :class='{ [`is-${color}`]: color }'>
        <div class='prg'
          :class='{ [`is-${color}`]: color }'
          :style='{ width: `${value / max * 100}%` }'>
        </div>
      </div>
      <div class='txt'>{{ `${value}/${max}` }}</div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      label: String,
      color: String,
      value: Number,
      max: Number
    }
  }
</script>

<style lang='scss' scoped>
  @use 'sass:map';
  @use '@/styles/variables';

  .lbl {
    margin-bottom: 8px;
    color: map.get(variables.$colors, 'gray', '500');
    font-size: map.get(variables.$font-sizes, 'sm');
  }

  .con {
    display: flex;
    align-items: center;
    height: 20px;
  }

  .lin {
    padding-left: 2px;
    width: 100%;
    height: 2px;
    background-color: map.get(variables.$colors, 'gray', '300');

    @each $name, $color in variables.$colors {
      &.is-#{$name} {
        background-color: map.get($color, '300');
      }
    }
  }

  .prg {
    position: relative;
    height: 2px;
    background-color: map.get(variables.$colors, 'primary', '500');

    &::before {
      position: absolute;
      top: 0;
      left: -2px;
      width: 2px;
      height: 2px;
      background-color: map.get(variables.$colors, 'primary', '500');
      content: '';
    }

    &::after {
      position: absolute;
      top: -2px;
      right: 0;
      width: 2px;
      height: 6px;
      background-color: map.get(variables.$colors, 'primary', '500');
      content: '';
    }

    @each $name, $color in variables.$colors {
      &.is-#{$name} {
        background-color: map.get($color, '500');

        &::before,
        &::after {
          background-color: map.get($color, '500');
        }
      }
    }
  }

  .txt {
    flex-shrink: 0;
    width: 64px;
    font-size: map.get(variables.$font-sizes, 'sm');
    text-align: right;
  }

  @media (min-width: map.get(variables.$breakpoints, 'lg')) {
    .con {
      height: 28px;
    }

    .lin {
      padding-left: 4px;
      height: 4px;
    }

    .prg {
      height: 4px;

      &::before {
        left: -4px;
        width: 4px;
        height: 4px;
      }

      &::after {
        top: -4px;
        width: 4px;
        height: 12px;
      }
    }

    .txt {
      width: 80px;
    }
  }
</style>
